import React from "react"
import Button from "../Button"
import Url from "../common/Url"
import Img from "../Img"
import * as style from "./Partners.module.scss"

const Partners = ({ data, lang, smaller }) => {
  const { title, subtitle, partners, cta, bgColor } = data

  const bgClass = bgColor === "sand" ? "bg-sand" : "bg-white"
  return (
    <div
      className={`${bgClass} ${smaller ? "p-lg-5" : ""} ${
        style.partners
      } flex flex-lg-row flex-column  `}
    >
      <div
        className={`${style.left} my-lg-0 my-4 flex flex-column justify-content-between pe-lg-5 col-12 col-lg-4 text-start`}
      >
        <div>
          <div className={`h1sizing copper ${style.title}`}>{title}</div>
          <div className={`h2sizing ming`}>{subtitle}</div>
        </div>
        {cta && (
          <Button
            lang={lang}
            type={cta.template}
            text={cta.buttonLabel}
            url={cta.url}
            className={`d-lg-block d-none`}
          />
        )}
      </div>
      <div className={`${style.right} ps-lg-5 d-grid col-12 col-lg-8`}>
        {partners.map((p, index) => {
          return p.slug ? (
            <Url url={"/partners/" + p.slug} lang={lang}>
              <Img key={index} image={p.logo} className={`${style.logo}`} />
            </Url>
          ) : (
            <Img key={index} image={p.logo} className={`${style.logo}`} />
          )
        })}
      </div>

      {cta && (
        <div className={`d-lg-none d-block mt-4 flex`}>
          <Button
            lang={lang}
            type={cta.template}
            text={cta.buttonLabel}
            url={cta.url}
            className={``}
          />
        </div>
      )}
    </div>
  )
}

export default Partners
