// extracted by mini-css-extract-plugin
export var alertRed = "Partners-module--alert-red--f04e9";
export var bgBarGrey = "Partners-module--bg-bar-grey--9d158";
export var bgBeige = "Partners-module--bg-beige--ef2e9";
export var bgBeige2 = "Partners-module--bg-beige2--6b3c6";
export var bgCopper = "Partners-module--bg-copper--0c6d2";
export var bgDaffodil = "Partners-module--bg-daffodil--0b98a";
export var bgGreyText = "Partners-module--bg-grey-text--12ece";
export var bgMing = "Partners-module--bg-ming--2efb7";
export var bgMint = "Partners-module--bg-mint--21fa4";
export var bgRed = "Partners-module--bg-red--b06ae";
export var bgRedTrans = "Partners-module--bg-red-trans--b671f";
export var bgSand = "Partners-module--bg-sand--d4a84";
export var bgSoftSun = "Partners-module--bg-softSun--dad8b";
export var bgTeal = "Partners-module--bg-teal--317c5";
export var bgWhite = "Partners-module--bg-white--23ca5";
export var bgWomenSharp = "Partners-module--bg-women-sharp--bc50f";
export var blackText = "Partners-module--black-text--81076";
export var borderMing = "Partners-module--border-ming--0ea7e";
export var copper = "Partners-module--copper--05047";
export var corners = "Partners-module--corners--30afe";
export var cursorNormal = "Partners-module--cursor-normal--bcf58";
export var cursorPointer = "Partners-module--cursor-pointer--da82e";
export var customMarkdown = "Partners-module--customMarkdown--9cc01";
export var dUnset = "Partners-module--d-unset--04172";
export var darkGrey = "Partners-module--dark-grey--948cc";
export var dash = "Partners-module--dash--20d8f";
export var dashBig = "Partners-module--dashBig--3647a";
export var deadSalmon = "Partners-module--dead-salmon--0615c";
export var flex = "Partners-module--flex--1dfe0";
export var flex1 = "Partners-module--flex-1--1b457";
export var fontSize12 = "Partners-module--font-size-12--449a8";
export var fontSize20 = "Partners-module--font-size-20--fe7bf";
export var greyPlaceholder = "Partners-module--grey-placeholder--d14d2";
export var greyText = "Partners-module--grey-text--f401c";
export var h1sizing = "Partners-module--h1sizing--5fc8e";
export var h2sizing = "Partners-module--h2sizing--16198";
export var hidden = "Partners-module--hidden--608e8";
export var imageAni = "Partners-module--imageAni--10418";
export var imageZoom = "Partners-module--imageZoom--ca97c";
export var imageZoomGatsby = "Partners-module--imageZoomGatsby--c6620";
export var italic = "Partners-module--italic--5fa77";
export var left = "Partners-module--left--3e34d";
export var lightGrey = "Partners-module--light-grey--2024c";
export var logo = "Partners-module--logo--06704";
export var lora = "Partners-module--lora--e6fc0";
export var loraBold = "Partners-module--lora-Bold--78335";
export var loraBoldItalic = "Partners-module--lora-BoldItalic--10278";
export var loraMedium = "Partners-module--lora-Medium--2f786";
export var loraSemiBold = "Partners-module--lora-SemiBold--f67fe";
export var main = "Partners-module--main--def00";
export var ming = "Partners-module--ming--83651";
export var mingHover2 = "Partners-module--ming-hover-2--5b171";
export var modalOpen = "Partners-module--modal-open--ba1cf";
export var noUnderline = "Partners-module--no-underline--b35a4";
export var openSans = "Partners-module--openSans--71c00";
export var openSansBold = "Partners-module--openSans-Bold--bb3cc";
export var openSansSemiBold = "Partners-module--openSans-SemiBold--b4910";
export var pageGuide = "Partners-module--page-guide--21b40";
export var partners = "Partners-module--partners--044b8";
export var popUp = "Partners-module--popUp--c5e3f";
export var popUpAni = "Partners-module--pop-up-ani--502bf";
export var quotation = "Partners-module--quotation--aada7";
export var right = "Partners-module--right--b0c40";
export var round = "Partners-module--round--cf0ec";
export var roundCorners = "Partners-module--round-corners--dc6b6";
export var softCorners = "Partners-module--soft-corners--2bcff";
export var softShadows = "Partners-module--soft-shadows--ec071";
export var softerCorners = "Partners-module--softer-corners--7d2c1";
export var softyCorners = "Partners-module--softy-corners--6c875";
export var tableShadow = "Partners-module--table-shadow--913a2";
export var teal = "Partners-module--teal--08939";
export var title = "Partners-module--title--ee435";
export var topNavShadow = "Partners-module--top-nav-shadow--a7b78";
export var topicArea = "Partners-module--topicArea--0b5a0";
export var uppercase = "Partners-module--uppercase--65d5a";
export var wFull = "Partners-module--w-full--cd98d";
export var white = "Partners-module--white--eda0b";
export var womenRed = "Partners-module--women-red--747e0";
export var womenSharp = "Partners-module--women-sharp--1e7b0";