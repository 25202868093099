// extracted by mini-css-extract-plugin
export var alertRed = "InsurancePlans-module--alert-red--ad78c";
export var blackText = "InsurancePlans-module--black-text--e2056";
export var copper = "InsurancePlans-module--copper--919b2";
export var darkGrey = "InsurancePlans-module--dark-grey--2bedc";
export var deadSalmon = "InsurancePlans-module--dead-salmon--2f6ae";
export var greyPlaceholder = "InsurancePlans-module--grey-placeholder--4a683";
export var greyText = "InsurancePlans-module--grey-text--a5848";
export var image = "InsurancePlans-module--image--2baa3";
export var insurancePlans = "InsurancePlans-module--insurancePlans--1da06";
export var left = "InsurancePlans-module--left--77d3a";
export var lightGrey = "InsurancePlans-module--light-grey--6b2a0";
export var ming = "InsurancePlans-module--ming--7d09e";
export var mingHover2 = "InsurancePlans-module--ming-hover-2--9e356";
export var planDetails = "InsurancePlans-module--planDetails--7378b";
export var right = "InsurancePlans-module--right--3a4cf";
export var selected = "InsurancePlans-module--selected--4964b";
export var tag = "InsurancePlans-module--tag--01770";
export var teal = "InsurancePlans-module--teal--37d4a";
export var white = "InsurancePlans-module--white--e99b6";
export var womenRed = "InsurancePlans-module--women-red--9da14";
export var womenSharp = "InsurancePlans-module--women-sharp--845da";